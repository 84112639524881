.container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.main-hero {
    background: url("../../../public/macaron-hero-large.jpg") no-repeat left center;
    background-size: cover;
    opacity: .9;
}

.article-title {
    text-transform: uppercase;
    font-family: 'Amatic SC', serif;
}

.reviewer-name {
    text-transform: uppercase;
    font-family: 'Amatic SC', serif;
}

.review-text {
    font-size: 1rem;
}