#order-date {
    height: 58px;
}

#order-date::placeholder {
    color: #212529;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}