#navbarSupportedContent {
    font-family: "Amatic SC", Arial, serif;
    font-size: 28px;
    text-transform: uppercase;
}

#header .nav-link {
    color: #655A4F !important;
}

#header .nav-link + .active {
    color: #08A4BD !important;
}

#header :hover {
    color: #08A4BD !important;
}

.navbar-brand {
    width: 100px;
    margin-left: 50px !important;
}
